<template>
  <div style="display: flex">
    <div style="width: 15%" v-if="!show">
      <draggable
          class="dragArea list-group"
          :list="list1"
          :clone="clone"
          style="min-height: 100px"
          :group="{ name: 'people', pull: pullFunction }"
          @start="start"
      >
        <div
            class="list-group-item"
            v-for="element in list1"
            :key="element.id"
        >
          <div class="style-info flexList justify-content-between">
            <div>
              <i class="iconfont icon-riLine-team-line mr-2 font-size-18"></i
              >{{ element.name }}
            </div>
          </div>
        </div>
      </draggable>
    </div>
    <div style="overflow-y: auto; padding: 5px;" :style="{width: show?'100%':'70%'}">
      <div style="overflow: hidden">
        <draggable
            class="outerCotent form-sure flexList"
            style="flex-wrap: wrap; width: 66%; padding: 5px 0;align-items: flex-start;float: left"
            :list="list2"
            group="people"
            @add="addOk"
            :disabled="show"
        >
          <div
              class="msgContent flexList fs-xs"
              :class="'w' + element.width"
              v-for="element in list2"
              :key="element.id"
              @click="chooseEle(element)"
          >
            <div class="msgVal selectShow flexList">
              <div class="flexList col-sm-8 msgLabel">
                <div class="msgName label-required">{{ element.name }}</div>
                <div class="msgVal  flexList w-33">
                  <div class="line1 use-circle" :keyname='element.id'>{{ form[element.bound] }}</div>
                </div>
              </div>
            </div>
          </div>
        </draggable>
        <div style="width: 33%;text-align: center;height: 315px;line-height: 315px; float: right" class="personPic">
          <img src="@/assets/images/person/person2.png">
        </div>
      </div>
      <draggable
          class="outerCotent form-sure flexList"
          style="flex-wrap: wrap; width: 100%; padding: 5px 0;align-items: flex-start;"
          :list="list3"
          group="people"
          @add="addOk"
          :disabled="show"
      >
        <div
            class="msgContent flexList fs-xs"
            :class="'w' + element.width"
            v-for="element in list3"
            :key="element.id"
            @click="chooseEle(element)"
        >
          <div class="msgVal selectShow flexList">
            <div class="flexList col-sm-8 msgLabel">
              <div class="msgName label-required">{{ element.name }}</div>
              <div class="msgVal  flexList w-33">
                <div class="line1 use-circle"  :keyname='element.id'>{{ form[element.bound] }}</div>
              </div>
            </div>
          </div>
        </div>
      </draggable>
    </div>
    <div style="width: 15%" v-if="!show">
      <div class="mt-3 exam-form" v-if="chooseId.name">
        <div class="check-distri mb-3">
          <label>宽度百分比：({{ chooseId.name }})</label>
          <div class="col-sm-10 p-0 flexList">
            <yz-select
                :dict="[
                  { name: '20%', value: '20' },
                  { name: '33%', value: '33' },
                  { name: '50%', value: '50' },
                  { name: '66%', value: '66' },
                  { name: '100%', value: '100' },
                ]"
                :bound="['width']"
                v-model="chooseId"
            ></yz-select>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery"
import draggable from "vuedraggable";
import YzSelect from "@/components/form/yzSelect.vue";
export default {
  components: {
    draggable,
    YzSelect,
  },
  model:{
    prop: "data",
    event: "input"
  },
  props:{
    data: Array,
    form: Object,
    show: Boolean
  },
  data() {
    return {
      dataOut: [],
      chooseId: [],
      errorInfoList: [],
      infoList: {
      },
      list1: [],
      list2: [
      ],
      list3:[
      ],
    }
  },
  methods: {
    chooseEle(e) {
      this.chooseId = e;
    },
    getRegisterInfo () {
      this.list2 = []
      this.list3 = []
      this.data.forEach(item=>{
        if (item.bound instanceof Array){
          if (item.bound.length > 1) {
            item.bound = item.bound[1]
          } else {
            item.bound = item.bound[0]
          }
        }
        if (item.listId == 1){
          this.list2.push(item)
        } else {
          this.list3.push(item)
        }
      })
    },
    addOk() {
      $(".msgVal .use-circle").on("click", this.circleItem)
      this.data = []
      this.list2.forEach(item=>{
        item.listId = 1
        this.data.push(item)
      })
      this.list3.forEach(item=>{
        item.listId = 2
        this.data.push(item)
      })
      this.$emit("input", this.data)
      this.$emit("change", this.data)
    },
    clone(e) {
      return JSON.parse(JSON.stringify(e));
    },
    pullFunction() {
      return this.controlOnStart ? "clone" : true;
    },
    start({originalEvent}) {
      this.controlOnStart = originalEvent.ctrlKey;
    },
    circleItem(e) {
      let key = $(e.currentTarget).attr("keyname");
      // console.log($(e.currentTarget))
      let index = this.errorInfoList.indexOf(key)
      if (index > -1) {
        //删除圈圈
        this.errorInfoList.splice(index, 1)
      } else {
        //添加圈圈
        this.errorInfoList.push(key)
      }
      this.renderCircle() //
      this.infoList.ischeck = this.errorInfoList.length ? '0' : "1"
      // $(e.currentTarget).toggleClass("circle");
    },
    renderCircle() {
      let errorInfo = this.errorInfoList;
      $(".use-circle.circle").removeClass("circle")
      errorInfo.forEach(k => {
        $(".use-circle[keyname=" + k + "]").addClass("circle")
      })

    },

  },
  mounted() {
    $(".msgVal .use-circle").on("click", this.circleItem)
    // 获取系统组件
    this.list1 = [
      {id: 100, name: "报考单位", bound: "bkdw", nameBound: "", width: 50},
      {id: 101, name: "姓名", bound: "xm", nameBound: "", width: 50},
      {id: 102, name: "性别", bound: "xb", nameBound: "", width: 50},
      {id: 103, name: "民族", bound: "mz", nameBound: "", width: 50},
    ]
    this.getRegisterInfo();
  },
  watch:{
    data:{
      deep: true,
      immediate: true,
      handler(){
        this.getRegisterInfo()
      },

    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/common.css";

.msgName {
  width: 150px;
  min-width: 150px;
}

.circle {
  position: relative;
  z-index: 50;
  transition: all .3s;
  cursor: pointer;

}

.use-circle {
  user-select: none;
}

.circle::after {
  content: " ";
  border: 1px solid red;
  border-radius: 50% / 50%;
  position: absolute;
  left: 0;
  top: 5px;
  z-index: 48;
  width: 100%;
  height: 30px;
}

.w20 {
  width: 20%;
}
.w33 {
  width: 33%;
}
.w50 {
  width: 50%;
}
.w66 {
  width: 66%;
}
.w100 {
  width: 100%;
}
</style>